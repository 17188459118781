import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useTypedSelector } from "../../store/app.store.tsx";
import { fetchTokens } from "../../store/authentication.reducer.tsx";

const TableauEmbed = ({ url, token, username, options }) => {
  const dispatch = useAppDispatch();
  const vizRef = useRef(null);
  const eventListenersRef = useRef({});
  const [vizUrl] = useState(url);

  const loading = useTypedSelector((state) => state.authentication.loading);
  const error = useTypedSelector((state) => state.authentication.error);
  const tableauToken = useTypedSelector(
    (state) => state.authentication.tableau_token
  );
  const selectedFacility = useTypedSelector(
    (state) => state.facility.selectedFacility
  );

  useEffect(() => {
    if (loading === "IDLE") {
      dispatch(fetchTokens({ email: username, token }));
    }
  }, [dispatch, loading, token, username]);

  useEffect(() => {
    if (loading !== "DONE" || !tableauToken || error) {
      return;
    }
    if (!vizRef.current) {
      vizRef.current = document.getElementById("tableauViz");
    }
    if (vizRef.current) {
      // addEventListener(TableauEventType.UrlAction, function (event) {
      //   const newUrl = event.detail._url;
      //   setVizUrl(newUrl);
      // });
    }

    return () => {
      removeEventListeners();
    };
  }, [error, loading, tableauToken]);

  useMemo(async () => {
    if (selectedFacility) {
      const viz = document.getElementById("tableauViz");
      if (viz) {
        try {
          const workbook = viz.workbook ?? null;
          if (workbook) {
            await workbook.changeParameterValueAsync(
              "my_facility",
              selectedFacility
            );
          }
        } catch (e) {}
      }
    }
  }, [selectedFacility]);

  const removeEventListeners = () => {
    if (vizRef.current) {
      for (const eventName in eventListenersRef.current) {
        const handler = eventListenersRef.current[eventName];
        vizRef.current.removeEventListener(eventName, handler);
      }
      eventListenersRef.current = {};
    }
  };

  if (loading !== "DONE") {
    return <h1>Loading</h1>;
  }

  if (!tableauToken) {
    return (
      <div>
        <div className="no-access">
          <span>
            Unable to connect to PFS servers. If you are connected to a VPN (such as
            FRAC), please disconnect from the VPN and try again.
            If you are unable to resolve this issue, please contact PFS-Support@cssiinc.com.
          </span>
        </div>
        <br/>
        <span>Error: unable to authenticate user with Tableau.</span>
      </div>
      );
  }

  if (error) {
    return <h3 className="error">{error}</h3>;
  }

  return (
    <div className="tableau-container">
      <tableau-viz
        id="tableauViz"
        className="tableau-viz"
        src={vizUrl + `&my_facility=${selectedFacility}`}
        token={tableauToken}
        {...options}
      >
        <viz-parameter
          name="my_facility"
          value={selectedFacility}
        ></viz-parameter>
        <viz-parameter name=":linktarget" value="_self"></viz-parameter>
        <viz-parameter name="token" value={token}></viz-parameter>
        <viz-parameter name=":embed" value="y"></viz-parameter>
      </tableau-viz>
    </div>
  );
};

export default TableauEmbed;
