import React, { useMemo } from "react";

const Tableau = ({
  url,
  options,
  selectedFacility,
  tableauToken,
  pfsToken,
}) => {
  useMemo(async () => {
    const viz = document.getElementById("tableauViz");
    try {
      if (viz && viz.workbook && viz.workbook.getParametersAsync) {
        const parameters = (await viz.workbook?.getParametersAsync()) ?? [];
        const facility = parameters.find((p) => p.name === "my_facility");
        if (facility && selectedFacility) {
          await facility.changeValueAsync(selectedFacility);
        }
      }
    } catch (e) {
      console.log("failed to update facility due to ", e);
    }
  }, [selectedFacility]);

  return (
    <div className="tableau-container">
      <tableau-viz
        id="tableauViz"
        className="tableau-viz"
        src={`${url}?:device=desktop&:linktarget=_self&:origin=card_share_link&:toolbar=no`}
        {...(url.toLowerCase().includes('pfsportal.org') && tableauToken ? { token: tableauToken } : {})}
        {...options}
      >
        <viz-parameter
          name="my_facility"
          value={selectedFacility}
        ></viz-parameter>
        <viz-parameter name="token" value={pfsToken}></viz-parameter>
        <viz-parameter name=":embed" value="yes"></viz-parameter>
      </tableau-viz>
    </div>
  );
};

export default Tableau;
