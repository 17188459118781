import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Tabs,
  TabsProps,
  Spin,
  Flex,
  List,
  Empty,
  Typography,
  Checkbox,
  Input,
  Table,
  Alert,
  Select,
  Popconfirm,
  Modal,
} from "antd";
import {
  EditOutlined,
  ArrowLeftOutlined,
  CloseCircleOutlined,
  SaveOutlined,
  DeleteOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

import { useAppDispatch, useTypedSelector } from "../../../store/app.store.tsx";
import {
  FetchUserDetails,
  FetchUserGroupsDetails,
  FetchUserSitesDetails,
  UpdateUserGroupsDetails,
} from "../../../store/user.thunk.tsx";
import {
  CreateUserData,
  ProfileAction,
  SelectedUserData,
  SiteTableHeader,
  UserDetailsInfo,
} from "../../../interfaces/user.interface.tsx";

import "./UserDetails.scss";
import {
  clearSelectedUser,
  fetchUsers,
  SetSelectedUserDetails,
  SetSelectedUserSites,
} from "../../../store/user.reducer.tsx";
import { fetchAllFacilities } from "../../../store/facilities.reducer.tsx";
import { Group } from "../../../interfaces/auth.interface.tsx";
import { fetchRoles } from "../../../store/authentication.reducer.tsx";
import { FetchAppRoles } from "../../../store/roles.thunk.tsx";

const { Column } = Table;

const iconMap = {
  edit: <EditOutlined />,
  back: <ArrowLeftOutlined />,
  cancel: <CloseCircleOutlined />,
  delete: <DeleteOutlined />,
};

export const ProfileHeaderWithActions = (props: {
  userDetails: UserDetailsInfo;
  actions: ProfileAction[];
  onAction: (name: string) => void;
  editMode: boolean;
}) => {
  const { userDetails, actions, onAction, editMode } = props;
  return (
    <section className="profile-header">
      <span className="fullname">
        {userDetails.first_name} {userDetails.last_name}
      </span>
      <div className="action-groups">
        {actions.map((action: ProfileAction) =>
          editMode && action.name === "edit" ? (
            <Button
              variant="solid"
              color="primary"
              icon={iconMap.cancel}
              size="middle"
              onClick={() => onAction("cancelEditMode")}
              key={`action-button-cancelEditMode`}
            >
              Done
            </Button>
          ) : (
            <Button
              type="default"
              icon={iconMap[action.iconName]}
              size="middle"
              onClick={() => onAction(action.name)}
              key={`action-button-${action.name}`}
              disabled={editMode}
            >
              {action.friendlly}
            </Button>
          )
        )}
      </div>
    </section>
  );
};

export const AccountDetails = (props: {
  userInfo: UserDetailsInfo | null;
  token: string;
  editMode: boolean;
  setEditMode: (val: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const { userInfo, token, editMode, setEditMode } = props;
  const loadingAppRoles = useTypedSelector((state) => state.roles.loading);
  const appRoles = useTypedSelector((state) => state.roles.userRoles);
  const [sendingRegistrationEmail, setSendingRegistrationEmail] =
    useState<boolean>(false);
  const [showSendingEmailErrorBanner, setShowSendingEmailErrorBanner] =
    useState<boolean>(false);
  const [showSendingEmailSuccessBanner, setShowSendingEmailSuccessBanner] =
    useState<boolean>(false);
  const [editedUser, setEditedUser] = useState<CreateUserData | null>(null);
  const [updating, setUpdating] = useState<boolean>(false);
  const [updatingError, setUpdatingError] = useState<string>("");
  const [activating, setActivating] = useState<boolean>(false);
  const [activatingError, setActivationError] = useState<string | null>(null);
  const [deactivatingError, setDeactivationError] = useState<string | null>(
    null
  );
  const [deactivateFeedbacks, setDeactivateFeedbacks] = useState<
    { message: string; success: boolean }[]
  >([]);
  const [activateFeedbacks, setActivateFeedbacks] = useState<
    { message: string; success: boolean }[]
  >([]);

  useEffect(() => {
    if (loadingAppRoles === "IDLE") {
      dispatch(FetchAppRoles({ token }));
    }
    if (editMode && userInfo) {
      setEditedUser({
        active: userInfo?.active,
        email_address: userInfo?.email_address,
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
        middle_name: userInfo?.middle_name,
        role_id: userInfo?.role_id,
      });
    }
  }, [dispatch, editMode, loadingAppRoles, token, userInfo]);

  const sendRegistrationEmail = async () => {
    setShowSendingEmailErrorBanner(false);
    setShowSendingEmailSuccessBanner(false);
    setSendingRegistrationEmail(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/users/${userInfo?.user_id}/registration-email`,
        {
          method: "POST",
          headers: { Authorization: token },
        }
      );

      if (response.ok) {
        setShowSendingEmailSuccessBanner(true);
      } else {
        setShowSendingEmailErrorBanner(true);
      }
    } catch {
      setShowSendingEmailErrorBanner(true);
    } finally {
      setSendingRegistrationEmail(false);
    }
  };

  const updateEditedUserField = (fieldName: string, value: any): void => {
    const data = { ...editedUser, [fieldName]: value } as any;
    setEditedUser(data);
  };

  const handleRoleChange = (evt) => {
    updateEditedUserField("role_id", evt);
  };

  const updateUserDetails = async () => {
    const data = {
      ...userInfo,
      ...editedUser,
    };
    setUpdating(true);
    setUpdatingError("");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/users/${data.user_id}`,
        {
          method: "PUT",
          headers: { Authorization: token },
          body: JSON.stringify({
            user_id: data.user_id,
            last_name: data.last_name?.trim(),
            first_name: data.first_name?.trim(),
            middle_name: data.middle_name?.trim(),
            email_address: data.email_address?.trim(),
            role_id:
              data.role_id instanceof Array ? data.role_id[0] : data.role_id,
            active: data.active ? 1 : 0,
          }),
        }
      );

      const jsonResponse: any = await response.text();

      if (response.ok && data.user_id) {
        dispatch(
          FetchUserDetails({
            userId: data.user_id.toString(),
            token,
            force: true,
          })
        );
        dispatch(fetchUsers({ token }));
        setEditMode(false);
      } else {
        setUpdatingError(jsonResponse.message);
      }
    } catch {
      setUpdatingError("Failed to update user");
    } finally {
      setUpdating(false);
    }
  };

  const checkAccountDeactivationStatus = async (
    jobId: string,
    user_id: number
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/accounts/register/${jobId}`,
        {
          method: "GET",
          headers: { Authorization: token },
        }
      );
      const jsonResponse = await response.json();
      if (["SUCCESS", "FAILED"].includes(jsonResponse.status)) {
        setDeactivateFeedbacks(jsonResponse.steps);
        if (jsonResponse.status === "SUCCESS") {
          const userResponse = await fetch(
            `${process.env.REACT_APP_PFS_BASE_URL}/users/${user_id}`,
            {
              method: "GET",
              headers: { Authorization: token },
            }
          );
          const userJsonResponse = await userResponse.json();
          if (response.ok) {
            dispatch(SetSelectedUserDetails(userJsonResponse));
          }
        }
      } else {
        return await checkAccountDeactivationStatus(jobId, user_id);
      }
    } catch (error) {
      console.log("Error checking deactivation status", error);
    }
  };

  const deactivateUserAccount = async () => {
    setDeactivationError(null);
    if (!userInfo?.user_id) {
      return;
    }
    setActivating(true);
    setDeactivateFeedbacks([]);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/accounts/deactivation`,
        {
          method: "POST",
          headers: { Authorization: token },
          body: JSON.stringify({ user_id: userInfo?.user_id }),
        }
      );
      const jsonResponse = await response.json();
      if (response.ok) {
        await checkAccountDeactivationStatus(
          jsonResponse.job_id,
          userInfo?.user_id
        );
      } else {
        setDeactivationError("Failed to deactivate user");
      }
    } catch (error) {
      // add failure message
      setDeactivationError("Failed to deactivate user");
    } finally {
      setActivating(false);
    }
  };

  const checkAccountActivationStatus = async (
    jobId: string,
    user_id: number
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/accounts/register/${jobId}`,
        {
          method: "GET",
          headers: { Authorization: token },
        }
      );
      const jsonResponse = await response.json();
      console.log("Activation response", jsonResponse, response.status);

      if (["SUCCESS", "FAILED"].includes(jsonResponse.status)) {
        if (jsonResponse.status === "SUCCESS") {
          const userResponse = await fetch(
            `${process.env.REACT_APP_PFS_BASE_URL}/users/${user_id}`,
            {
              method: "GET",
              headers: { Authorization: token },
            }
          );
          const userJsonResponse = await userResponse.json();
          setActivateFeedbacks(jsonResponse.steps);
          if (response.ok) {
            dispatch(SetSelectedUserDetails(userJsonResponse));
          }
        } else {
          setActivationError("Failed to activate user");
        }
      } else {
        return await checkAccountActivationStatus(jobId, user_id);
      }
    } catch (error) {
      console.log("Error checking deactivation status", error);
    }
  };

  const activateUserAccount = async () => {
    setActivationError(null);
    if (!userInfo?.user_id) {
      return;
    }
    setActivating(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/accounts/activation`,
        {
          method: "POST",
          headers: { Authorization: token },
          body: JSON.stringify({ user_id: userInfo?.user_id }),
        }
      );

      const jsonResponse = await response.json();
      if (response.ok) {
        await checkAccountActivationStatus(
          jsonResponse.job_id,
          userInfo?.user_id
        );
      } else {
        setActivationError("Failed to activate user");
      }
    } catch (error) {
      console.log("Error activating user", error);
      setActivationError("Failed to deactivate user");
    } finally {
      setActivating(false);
    }
  };

  const listItems = userInfo
    ? [
        <div className="list-item" key="list-item-first-name">
          <Flex justify="flex-start" align="center">
            <span className="title">First Name: </span>
            {editMode ? (
              <Input
                placeholder="First Name"
                value={editedUser?.first_name}
                onChange={(evt) =>
                  updateEditedUserField("first_name", evt.target.value)
                }
                disabled={updating}
              />
            ) : (
              <span className="value">{userInfo.first_name}</span>
            )}
          </Flex>
        </div>,
        <div className="list-item" key="list-item-last-name">
          <Flex justify="flex-start" align="center">
            <span className="title">Last Name: </span>
            {editMode ? (
              <Input
                placeholder="Last Name"
                value={editedUser?.last_name}
                onChange={(evt) =>
                  updateEditedUserField("last_name", evt.target.value)
                }
                disabled={updating}
              />
            ) : (
              <span className="value">{userInfo.last_name}</span>
            )}
          </Flex>
        </div>,
        <div className="list-item" key="list-item-middle-name">
          <Flex justify="flex-start" align="center">
            <span className="title">Middle Name: </span>
            {editMode ? (
              <Input
                placeholder="Middle Name"
                value={editedUser?.middle_name}
                onChange={(evt) =>
                  updateEditedUserField("middle_name", evt.target.value)
                }
                disabled={updating}
              />
            ) : (
              <span className="value">{userInfo.middle_name}</span>
            )}
          </Flex>
        </div>,
        <div className="list-item" key="list-item-email-address">
          <Flex justify="flex-start" align="center">
            <span className="title">Email Address: </span>
            {editMode ? (
              <Input
                placeholder="Email Address"
                value={editedUser?.email_address}
                onChange={(evt) =>
                  updateEditedUserField("email_address", evt.target.value)
                }
                disabled={updating}
              />
            ) : (
              <span className="value">{userInfo.email_address}</span>
            )}
          </Flex>
        </div>,
        editMode ? null : (
          <div className="list-item" key="list-item-user-id">
            <Flex justify="flex-start" align="center">
              <span className="title">User Id: </span>
              <span className="value">{userInfo.user_id}</span>
            </Flex>
          </div>
        ),
        <div className="list-item" key="list-item-role-id">
          <Flex justify="flex-start" align="center">
            <span className="title">Roles: </span>
            {editMode ? (
              <Select
                style={{ width: "100%" }}
                placeholder="Please select"
                value={editedUser?.role_id}
                onChange={handleRoleChange}
                disabled={updating}
                loading={!appRoles || loadingAppRoles !== "DONE"}
              >
                {appRoles.map((option) => (
                  <Select.Option value={option.role_id} key={option.role_id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <span className="value">
                {userInfo?.role_id
                  .map((role_id) => {
                    const roleDetails = appRoles.find(
                      (r) => r.role_id === role_id
                    );
                    if (roleDetails) {
                      return roleDetails.name;
                    }
                    return null;
                  })
                  .filter((r) => r)
                  .join(",") ?? "N/A"}
              </span>
            )}
          </Flex>
        </div>,
        editMode ? null : (
          <div className="list-item" key="list-item-status">
            <Flex justify="space-between" align="center">
              <div>
                <span className="title">Active: </span>
                <span className="value">{userInfo.active ? "Yes" : "No"}</span>
              </div>
              {userInfo.active ? (
                <Button onClick={deactivateUserAccount} loading={activating}>
                  Deactivate
                </Button>
              ) : (
                <Button loading={activating} onClick={activateUserAccount}>
                  Activate
                </Button>
              )}
            </Flex>
          </div>
        ),
        editMode ? null : (
          <div className="list-item" key="list-item-registered">
            <Flex justify="space-between" align="center">
              <div>
                <span className="title">Registered: </span>
                <span className="value">
                  {userInfo.registered ? "Yes" : "No"}
                </span>
              </div>
            </Flex>
          </div>
        ),
        editMode ? null : (
          <div className="list-item" key="list-item-registration-email">
            <Flex justify="space-between" align="center">
              <div>
                <span className="title">Registration Email Sent Date: </span>
                <span className="value">
                  {userInfo.registration_email_sent_date ?? "Never"}
                </span>
              </div>
              <Button
                onClick={sendRegistrationEmail}
                loading={sendingRegistrationEmail}
              >
                {userInfo.registration_email_sent_date
                  ? "Resend Registration Email"
                  : "Send Registration Email"}
              </Button>
            </Flex>
          </div>
        ),
        editMode ? null : (
          <div className="list-item" key="list-item-created-date">
            <Flex justify="flex-start" align="center">
              <span className="title">Created: </span>
              <span className="value">
                {userInfo.created_date ? userInfo.created_date : "N/A"}
              </span>
            </Flex>
          </div>
        ),
      ]
    : [];

  return (
    <div className="user-info">
      <Modal
        title="Modal"
        open={deactivatingError !== null}
        onOk={() => setDeactivationError(null)}
        okText="OK"
      >
        <p>Failed to deactive user. Please contact your administrator.</p>
      </Modal>
      <Modal
        title="Modal"
        open={activatingError !== null}
        onOk={() => setActivationError(null)}
        okText="OK"
      >
        <p>Failed to activate user. Please contact your administrator.</p>
      </Modal>
      {showSendingEmailErrorBanner && (
        <Alert
          message="Server Error: An error occurred. Please contact your administrator."
          type="error"
          closable
          showIcon
          onClose={() => setShowSendingEmailErrorBanner(false)}
        />
      )}
      {updatingError ? (
        <Alert
          message={updatingError}
          type="error"
          closable
          showIcon
          onClose={() => setShowSendingEmailErrorBanner(false)}
        />
      ) : null}
      {showSendingEmailSuccessBanner ? (
        <Alert
          message={`Registration email has been sent to ${userInfo?.email_address}`}
          type="success"
          closable
          showIcon
          onClose={() => setShowSendingEmailSuccessBanner(false)}
        />
      ) : null}
      {deactivateFeedbacks.length ? (
        <Alert
          message={
            <>
              <p>Account Deactivated</p>
              <ul>
                {deactivateFeedbacks.map((line, index) => (
                  <li
                    key={`error-${index}`}
                    className={`${line.success ? "success" : "error"}`}
                  >
                    {line.message}
                  </li>
                ))}
              </ul>
            </>
          }
          type="success"
          closable
          onClose={() => setDeactivateFeedbacks([])}
        />
      ) : null}
      {activateFeedbacks.length ? (
        <Alert
          message={
            <>
              <p>Account Activation</p>
              <ul>
                {activateFeedbacks.map((line, index) => (
                  <li
                    key={`error-${index}`}
                    className={`${line.success ? "success" : "error"}`}
                  >
                    {line.message}
                  </li>
                ))}
              </ul>
            </>
          }
          type="success"
          closable
          onClose={() => setActivateFeedbacks([])}
        />
      ) : null}
      <List
        header={
          <div className="list-header">
            <Flex justify="space-between" align="center">
              <span className="title">Account: </span>
              {editMode && (
                <Button onClick={updateUserDetails} loading={updating}>
                  Update
                </Button>
              )}
            </Flex>
          </div>
        }
        size="small"
        bordered
        dataSource={listItems.filter((item) => item)}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </div>
  );
};

export const SiteDetails = (props: {
  userInfo: UserDetailsInfo | null;
  token: string;
  editMode: boolean;
}) => {
  const { userInfo, token, editMode } = props;
  const dispatch = useAppDispatch();

  const siteHeaders: SiteTableHeader[] = [
    {
      key: "group_id",
      dataIndex: "group_id",
      label: "Group",
      render: (data: any) =>
        `${data?.group_id ?? "N/A"} - ${data?.group_name ?? "N/A"}`,
      actions: [],
    },
    {
      key: "site",
      dataIndex: "site",
      label: "Site",
      actions: [],
      render: (data: any) => data.site,
    },
    {
      key: "role",
      dataIndex: "role",
      label: "Role",
      actions: [],
      render: (data: any) => data.role,
    },
    {
      key: "actions",
      dataIndex: "actions",
      label: "Action(s)",
      actions: ["delete"],
    },
  ];

  const loading = useTypedSelector(
    (state) => state.user.selectedUser.loadingUserSite
  );

  const userSites = useTypedSelector(
    (state) => state.user.selectedUser.data.userSites
  );
  const loadingSites = useTypedSelector(
    (state) => state.facility.fetchingFacilities
  );
  const appRoles = useTypedSelector((state) => state.roles.siteRoles);
  const allSites = useTypedSelector((state) => state.facility.allFacilities);
  const [deleting, setDeleting] = useState<number[]>([]);
  const [adding, setAdding] = useState<boolean>(false);
  const [showAddSiteModal, setShowAddSiteModal] = useState<boolean>(false);
  const [siteToAdd, setSiteToAdd] = useState<{
    facility_id: string;
    role_id: string;
  }>({ facility_id: "", role_id: "" });

  useEffect(() => {
    if (loading === "IDLE" && userInfo?.user_id) {
      dispatch(
        FetchUserSitesDetails({ userId: userInfo?.user_id.toString(), token })
      );
    }

    if (loadingSites === "IDLE") {
      dispatch(fetchAllFacilities({ token }));
    }
  }, [dispatch, loading, loadingSites, token, userInfo?.user_id]);

  useEffect(() => {}, [siteToAdd]);
  const deleteSite = async (site: any, index: number) => {
    const payload = {
      role_id: site.role_id,
      facility_id: site.site_id,
    };
    if (!userInfo?.user_id) {
      return;
    }
    setDeleting([...deleting, index]);
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_PFS_BASE_URL
        }/users/${userInfo.user_id.toString()}/sites`,
        {
          method: "DELETE",
          headers: { Authorization: token },
          body: JSON.stringify(payload),
        }
      );
      const jsonResponse = await response.json();
      if (response.ok) {
        dispatch(SetSelectedUserSites(jsonResponse));
      }
    } catch {
      console.log("Error deleting");
    } finally {
      setDeleting(deleting.filter((i) => i === index));
    }
  };

  const addSite = async () => {
    if (!userInfo?.user_id) return;
    setAdding(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/users/${userInfo.user_id}/sites`,
        {
          method: "PUT",
          headers: { Authorization: token },
          body: JSON.stringify(siteToAdd),
        }
      );

      if (response.ok) {
        dispatch(
          FetchUserSitesDetails({ userId: userInfo?.user_id.toString(), token })
        );
      }
    } catch {
      console.log("error adding site");
    } finally {
      setAdding(false);
      setShowAddSiteModal(false);
    }
  };

  if (loadingSites !== "DONE" || loading !== "DONE") {
    return (
      <Flex
        gap="middle"
        vertical
        className="loader-container"
        align="center"
        justify="center"
      >
        <Flex gap="middle">
          <Spin tip="Loading..." size="large">
            <div>Loading</div>
          </Spin>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex vertical>
      <Modal
        title="Add Site Role"
        open={showAddSiteModal}
        onOk={addSite}
        onCancel={() => {
          setShowAddSiteModal(false);
          setSiteToAdd({ facility_id: "", role_id: "" });
        }}
        okText="Add"
        cancelText="Cancel"
        okButtonProps={{
          disabled: !siteToAdd.role_id || !siteToAdd.facility_id,
          loading: adding,
        }}
      >
        <Flex gap="middle" vertical>
          <Select
            showSearch
            placeholder="Select a Site"
            optionFilterProp="name"
            options={allSites}
            fieldNames={{ label: "name", value: "facility_id" }}
            onChange={(e: any) =>
              setSiteToAdd({ ...siteToAdd, facility_id: e })
            }
          />
          <Select
            showSearch
            placeholder="Select a Role"
            optionFilterProp="name"
            options={appRoles}
            fieldNames={{ label: "name", value: "role_id" }}
            onChange={(e: any) => setSiteToAdd({ ...siteToAdd, role_id: e })}
          />
        </Flex>
      </Modal>
      <Table
        dataSource={userSites}
        locale={{
          emptyText: (
            <Empty description="Not Data">
              <Button onClick={() => setShowAddSiteModal(true)}>Add</Button>
            </Empty>
          ),
        }}
      >
        {siteHeaders.map((header: SiteTableHeader) => {
          switch (header.key) {
            case "actions":
              return editMode ? (
                <Column
                  showSorterTooltip={{ target: "full-header" }}
                  title={
                    <div className="space-between">
                      <span>{header.label}</span>
                      <Button
                        type="primary"
                        shape="round"
                        icon={<PlusCircleFilled />}
                        onClick={() => setShowAddSiteModal(true)}
                        disabled={!editMode}
                      >
                        Add
                      </Button>
                    </div>
                  }
                  key={`${header.key}-${uuidv4()}`}
                  render={(data: any) => {
                    const isDeletable = data.group_id === null;
                    return isDeletable ? (
                      header.actions.map((action: string, index: number) => (
                        <Popconfirm
                          title="Delete the site"
                          description="Are you sure to delete this site?"
                          onConfirm={() => deleteSite(data, index)}
                          okText="Yes"
                          cancelText="No"
                          disabled={deleting.includes(index)}
                          key={`${header.key}-button-${uuidv4()}-${index}`}
                        >
                          <Button
                            color="danger"
                            variant="filled"
                            icon={iconMap[action]}
                            size="middle"
                            disabled={!editMode}
                            loading={deleting.includes(index)}
                          />
                        </Popconfirm>
                      ))
                    ) : (
                      <p>
                        This is a group role. To remove it, please simply
                        removed the user from the group.
                      </p>
                    );
                  }}
                />
              ) : null;
            default:
              return (
                <Column
                  title={header.label}
                  key={`${header.key}-${uuidv4()}`}
                  render={header.render}
                />
              );
          }
        })}
      </Table>
    </Flex>
  );
};

export const GroupDetails = (props: {
  userInfo: UserDetailsInfo | null;
  token: string;
  editMode: boolean;
}) => {
  const { userInfo, token, editMode } = props;
  const dispatch = useAppDispatch();
  const [groupFilter, setGroupFilter] = useState<string>("");
  const [selectedUserGroups, setSelectedUserGroups] = useState<number[]>([]);

  const loading = useTypedSelector(
    (state) => state.user.selectedUser.loadingUserGroup
  );
  const error = useTypedSelector(
    (state) => state.user.selectedUser.loadingUserGroupError
  );
  const userGroups = useTypedSelector(
    (state) => state.user.selectedUser.data.userGroups
  );
  const appGroups: Group[] = useTypedSelector(
    (state) => state.authentication.appGroups
  );
  const loadingGroups = useTypedSelector(
    (state) => state.authentication.loadingRoles
  );
  const loadingGroupsError = useTypedSelector(
    (state) => state.authentication.roleError
  );

  useEffect(() => {
    if (loading === "IDLE" && userInfo?.user_id) {
      dispatch(
        FetchUserGroupsDetails({ userId: userInfo.user_id.toString(), token })
      );
    }

    if (loadingGroups === "IDLE") {
      dispatch(fetchRoles({ token }));
    }
  }, [dispatch, loading, loadingGroups, token, userInfo?.user_id]);

  useMemo(() => {
    setSelectedUserGroups(userGroups);
  }, [userGroups]);

  const RenderGroupItem = (props: {
    group: Group;
    onChange: (group: Group) => void;
    checked: boolean;
    editMode: boolean;
  }) => (
    <Flex
      gap="middle"
      justify="space-between"
      align="center"
      className="group-item"
    >
      <div className="group-details">
        <span className="group-name">{props.group.name}</span>
        <span className="group-description">
          {props.group.description.length
            ? props.group.description
            : "No Description"}
        </span>
      </div>
      <Checkbox
        onChange={() => props.onChange(props.group)}
        checked={props.checked}
        disabled={!props.editMode}
      />
    </Flex>
  );

  const onGroupSelectionChange = (group: Group) => {
    setSelectedUserGroups(
      selectedUserGroups.includes(group.group_id)
        ? selectedUserGroups.filter(
            (groupId: number) => groupId !== group.group_id
          )
        : [...selectedUserGroups, group.group_id]
    );
  };

  const isSelected = (group: Group) => {
    return selectedUserGroups.includes(group.group_id);
  };

  const shouldUpdateGroups = (): boolean => {
    return (
      selectedUserGroups.some((value) => !userGroups.includes(value)) ||
      selectedUserGroups.length !== userGroups.length
    );
  };

  const updateUserGroups = () => {
    if (userInfo?.user_id) {
      dispatch(
        UpdateUserGroupsDetails({
          token,
          userId: userInfo?.user_id.toString(),
          groups: selectedUserGroups,
        })
      );
    }
  };

  if (error || loadingGroupsError) {
    return (
      <Flex justify="center" align="center" vertical style={{ height: "100%" }}>
        <Empty
          description={<Typography.Text>No Data found</Typography.Text>}
        ></Empty>
      </Flex>
    );
  }

  if (loadingGroups !== "DONE" || loading !== "DONE") {
    return (
      <Flex
        gap="middle"
        vertical
        className="loader-container"
        align="center"
        justify="center"
      >
        <Flex gap="middle">
          <Spin tip="Loading..." size="large">
            <div>Loading</div>
          </Spin>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex vertical gap={12}>
      <Flex gap={12}>
        <Input
          placeholder="Searching for group..."
          allowClear
          value={groupFilter}
          onChange={(evt) => setGroupFilter(evt.target.value)}
        />
        {editMode && (
          <Button
            disabled={!shouldUpdateGroups()}
            variant="filled"
            color="primary"
            onClick={updateUserGroups}
          >
            <SaveOutlined />
            Update
          </Button>
        )}
      </Flex>
      <Flex className="group-info">
        <List
          size="small"
          bordered
          className="group-info-list"
          dataSource={appGroups.filter((g: Group) =>
            editMode
              ? g.name.toLowerCase().includes(groupFilter.trim().toLowerCase())
              : g.name
                  .toLowerCase()
                  .includes(groupFilter.trim().toLowerCase()) && isSelected(g)
          )}
          renderItem={(item) => (
            <List.Item key={item.group_id} className="group-item">
              <RenderGroupItem
                group={item}
                onChange={onGroupSelectionChange}
                checked={isSelected(item)}
                editMode={editMode}
              />
            </List.Item>
          )}
        />
      </Flex>
    </Flex>
  );
};

export const ProfileDetails = (props: {
  userData: SelectedUserData;
  token: string;
  editMode: boolean;
  setEditMode: (val: boolean) => void;
}) => {
  const { userData, token, editMode, setEditMode } = props;
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Account",
      children: (
        <AccountDetails
          userInfo={userData.userInfo}
          token={token}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      ),
      disabled: editMode,
    },
    {
      key: "2",
      label: "Roles",
      children: (
        <SiteDetails
          userInfo={userData.userInfo}
          token={token}
          editMode={editMode}
        />
      ),
      disabled: editMode,
    },
    {
      key: "3",
      label: "Groups",
      children: (
        <GroupDetails
          userInfo={userData.userInfo}
          token={token}
          editMode={editMode}
        />
      ),
      disabled: editMode,
    },
  ];

  return (
    <div className="user-data">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export const UserDetails = (props: { token: string }) => {
  const { token } = props;
  const navigate = useNavigate();
  const dispath = useAppDispatch();
  const { userId } = useParams();

  const [editMode, setEditMode] = useState<boolean>(false);

  const loading = useTypedSelector((state) => state.user.selectedUser.loading);
  const error = useTypedSelector((state) => state.user.selectedUser.error);
  const userDetails = useTypedSelector((state) => state.user.selectedUser.data);
  const profileActions = useTypedSelector(
    (state) => state.user.selectedUser.profileActions
  );

  useEffect(() => {
    if (loading === "IDLE" && userId) {
      dispath(FetchUserDetails({ userId, token }));
    }
  }, [dispath, loading, token, userId]);

  const onAction = (actionName: string) => {
    switch (actionName) {
      case "back-to-list":
        navigate("/users");
        dispath(clearSelectedUser());
        break;
      case "edit":
        setEditMode(true);
        break;
      case "cancelEditMode":
        setEditMode(false);
        break;
      default:
        break;
    }
  };

  if (loading === "PENDING") {
    return (
      <Flex
        gap="middle"
        vertical
        className="loader-container"
        align="center"
        justify="center"
      >
        <Flex gap="middle">
          <Spin tip="Loading..." size="large">
            <div>Loading</div>
          </Spin>
        </Flex>
      </Flex>
    );
  }

  if (error || !userDetails.userInfo) {
    return (
      <Flex justify="center" align="center" vertical style={{ height: "100%" }}>
        <Empty description={<Typography.Text>User not found</Typography.Text>}>
          <Button onClick={() => onAction("back-to-list")}>Back to list</Button>
        </Empty>
      </Flex>
    );
  }

  return (
    <div className="user-details-container">
      <ProfileHeaderWithActions
        userDetails={userDetails.userInfo}
        actions={profileActions}
        onAction={onAction}
        editMode={editMode}
      />
      <ProfileDetails
        userData={userDetails}
        token={token}
        editMode={editMode}
        setEditMode={setEditMode}
      />
    </div>
  );
};
